@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Nunito+Sans");

html {
  font-family: Nunito Sans;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Light mode styles */
@media (prefers-color-scheme: light) {
  :root {
    --background-color: #ffffff; /* Light background color */
    --text-color: #000000; /* Dark text color for light background */
  }

  body {
    background-color: var(--background-color);
    color: var(--text-color);
  }
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #0f172a; /* Dark background color */
    --text-color: #f3f4f6; /* Light text color for dark background */
  }

  body {
    background-color: var(--background-color);
    color: var(--text-color);
  }
}


@layer base {
  :root {
    --background: #1a2026;
    --foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: #1a2026;
    --foreground: 213 31% 91%;
 
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
 
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
 
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
 
    --border: 216 34% 17%;
    --input: 216 34% 17%;
 
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
 
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
 
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 216 34% 17%;
 
    --radius: 0.5rem;
  }
}
 



#token-range::-webkit-slider-thumb {
    width: 0.8rem;
    height: 0.8rem;
    background-color: #3665E4;
    border-radius: 9999px;
    -webkit-appearance: none;
    appearance: none;
}

.date-input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}



.background-animate {
    background-size: 400%;

    -webkit-animation: AnimationName 15s ease infinite;
    -moz-animation: AnimationName 15s ease infinite;
    animation: AnimationName 15s ease infinite;
}

#gradient-canvas {
  width:100%;
  height:100%;
  --gradient-color-1: #047857; 
  --gradient-color-2: #1e40af; 
  --gradient-color-3: #0891B2; 
  --gradient-color-4: #1e40af; 
  box-shadow: 
              0 0 20px 5px var(--gradient-color-2),
              0 0 30px 30px var(--gradient-color-3),
              0 0 40px 40px var(--gradient-color-4);
}


#gradient-canvas2 {
  width:100%;
  height:100%;
  --gradient-color-1: #C2410C; 
  --gradient-color-2: #9F1239; 
  --gradient-color-3: #821CAF; 
  --gradient-color-4: #A855F7; 
  box-shadow: 
              0 0 20px 5px var(--gradient-color-2),
              0 0 30px 30px var(--gradient-color-3),
              0 0 40px 40px var(--gradient-color-4);
}

